import React from "react";
import Data from './../../data/Data';

export function FilterButtonMenu ({ filterItem, setItem, menuItems }) {

  return (
    <>
      <div className="filter-options">
        {menuItems.map((Val, id) => {
          return (
            <button onClick={() => filterItem(Val)} key={id}>{Val}</button>
          );
        })}
        <button onClick={() => setItem(Data)}>All</button>
      </div>
    </>
  );
};
