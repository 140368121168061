import { useState } from "react";
import { Route, Routes } from 'react-router-dom';
import { Navbar } from './Components/Navbar';
import { Sidebar } from './Components/Sidebar';
import { Home } from './pages/Home';
import { Contact } from './pages/Contact';
import { Projects } from './pages/Projects';
import './app.css';

export function App () {
  const [darkMode, setDarkMode ] = useState(true);

  return (
    <>
      <Navbar darkMode={darkMode} />
      <main>
        <Sidebar setDarkMode={setDarkMode}/>
        <Routes>
          <Route exact path="/" element={<Home />}/>
          <Route path="/projects" element={<Projects />}/>
          <Route path="/contact" element={<Contact />}/>
        </Routes>
      </main>
    </>
  );
}
