import { Link } from 'react-router-dom'

export function Navbar({ darkMode }) {
    const toggleBreadcrumb = (e) => {
        e.preventDefault();
        document.querySelector('#myLinks').classList.toggle('show');
    };
    let logo = darkMode ? "images/logo-black.png" : "images/logo-white.png";
    return (
        <header className="nav" data-aos="slide-down">
            <div className="nav_logo">
                <div id="logo_container">
                    <Link to="/"><img src={logo} alt="Gios Design"/></Link>
                </div>
                <div id="navbar_contact_container">
                    <div className="contact-icon"><i className="icon-paper-plane"></i></div>
                    <a href="mailto:giovaldezdev@gmail.com">giovaldezdev@gmail.com</a>
                </div>
            </div>
            <nav>
                <ul>
                    <li><a className="link" href="docs/Resume1.0.pdf" target="_blank" download="Gio Valdez CV">Resume</a></li>
                    <li><Link className="link" to="https://giovaldez.medium.com" target="_blank">Writing</Link></li>
                </ul>
                <div className="topnav">
                    <div id="myLinks">
                        <a href="https://giovaldez.medium.com" target="_blank">Writing</a>
                        <a href="docs/Resume1.0.pdf" target="_blank">Resume</a>
                    </div>
                    <a className="icon" onClick={toggleBreadcrumb}>
                    <i className="fa fa-bars"></i>
                    </a>
                </div>
            </nav>
        </header>
    );
}