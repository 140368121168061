const Data = [
    {
        id: "1",
        title: "Upsellit Company Website",
        category: "Websites",
        img: "/images/upsellit.png",
        desc: "Built with: Wordpress and PHP",
        url: "https://us.upsellit.com/"
    },
    {
        id: "2",
        title: "Cascade Company Website",
        category: "Websites",
        img: "/images/cascadefintech.png",
        desc: "Built with: C#, HTML, CSS, Javascript",
        url: "https://www.cascadefintech.com/"
    }];
  
  export default Data