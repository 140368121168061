import { Link } from 'react-router-dom';
import { useEffect } from 'react';

export function Home() {
    useEffect(() => {
        window['AOS'].init();
      }, []);
    return (
        <>
            <section id="about">
                <div>
                    <div className="fullname">
                        <div className="firstname" data-aos="fade-right" data-aos-delay="300">Giordano</div>
                        <div className="lastname" data-aos="fade-left" data-aos-delay="300">Valdez</div>
                    </div>
                    <div className="headline">Software Engineer and Writer</div>
                    <div className="description">Experienced web developer based in San Diego, CA who specializes in website creation and copywriting. I help small businesses gain and improve their online presence with responsive website development, SEO and design. If you need quick writeups about your business or help documenting your RESTful API's, I can also be of assistance. Let's get in touch today!</div>
                    <div className="linkscontainer">
                        {/* <Link to="/projects" className="button fancy"><i className="icon-bulb"></i> Projects</Link> */}
                        <Link to="mailto:giovaldezdev@gmail.com" className="button fancy"><i className="icon-pencil sm-size"></i>&nbsp;&nbsp;Contact Me</Link>
                    </div>
                </div>
                <div className="hero" data-aos="fade-left" data-aos-delay="1000">
                    <div className="hero_container">
                        <img src="images/Subject.png" alt="Gio"/> 
                    </div>
                </div>
            </section>
        </>
    );
}
