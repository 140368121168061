import React, { useRef, useEffect } from 'react'

export function Form({ handleSubmit }) {
    const emailInput = useRef();
    useEffect(() => {
        emailInput.current.focus();
    }, []);
    return (
        <>
            <h2 className="message">Let's get in touch today!</h2>
            <form id="contact-form" onSubmit={handleSubmit}>
                <label htmlFor="email">Email:</label>
                <input ref={emailInput} type="email" name="email" placeholder="Your email" required/>
                <label htmlFor="email">Message:</label>
                <textarea name="message" placeholder="Message" required/>
                <button type="submit" name="submit">Send Message</button>
            </form>
        </>
    )
}
