import React, { useState } from "react";
import { Card } from './../Components/Projects/Card';
import { FilterButtonMenu } from './../Components/Projects/FilterButtonMenu';
import Data from './../data/Data';

export function Projects() {
    const [item, setItem] = useState(Data);
    const menuItems = [...new Set(Data.map((Val) => Val.category))];
    const filterItem = (curcat) => {
      const newItem = Data.filter((newVal) => {
        return newVal.category === curcat;
      });
      setItem(newItem);
    };
    return (
      <>
        <section id="projects">
          <div className="row">
            <h1 className="title">Projects</h1>
            <FilterButtonMenu
              filterItem={filterItem}
              setItem={setItem}
              menuItems={menuItems}
            />
            <Card item={item} />
          </div>
        </section>
      </>
    );
}