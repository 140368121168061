import React from 'react'
import { Link } from 'react-router-dom'

export function ThankYou() {
    return (
        <>
            <h2>Thank you! I will respond to your request within 2-3 business days.</h2>
            <Link to="/" className="button gohome"><i className="icon-home"></i> Back to home</Link>
        </>
    )
}
