import React from "react";
import { Link } from 'react-router-dom'

export function Card({ item }) {
  return (
    <>
      <div className="entry-container">
        <div>
          {item.map((Val) => {
            return (
              <Link to={Val.url} className="entry" target="_blank" key={Val.id}>
                <div>
                  <img src={Val.img} alt={Val.title} />
                </div>
                <br></br>
                <div>
                  <div className="entry-title">
                    {Val.title}
                  </div>
                  <div className="description">{Val.desc}</div>
                </div>
              </Link>
            );
          })}
        </div>
      </div>
    </>
  );
};