import { DarkModeToggle } from './DarkModeToggle';

export function Sidebar({ setDarkMode }) {
    return (
        <aside data-aos="slide-right">
            <div className="socials"><a href="https://www.linkedin.com/in/glvaldez/" target="_blank" title="Linkedin"><i className="icon-social-linkedin"></i></a></div>
            <div className="socials"><a href="https://github.com/danov6" target="_blank" title="Github"><i className="icon-social-github"></i></a></div>
            <div className="socials"><a href="https://twitter.com/GiordanoValdez" target="_blank" title="Twitter"><i className="icon-social-twitter"></i></a></div>
            <DarkModeToggle setDarkMode={setDarkMode}/>
        </aside>
    );
}