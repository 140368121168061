import React, { useState, useEffect } from "react";
import { Form } from './../Components/Contact/Form';
import { ThankYou } from './../Components/Contact/ThankYou';

export function Contact() {
    const [submitted, setSubmitted ] = useState(false);
    const handleSubmit = (e) => {
        e.preventDefault();
        //fetch('')
        setSubmitted(true);
        console.log(e);
    };
    useEffect(() => {
        window['AOS'].init();
    }, []);
    return (
    <section id="contact">
        <h1 className="title">Contact</h1>
        <div className="hero" data-aos="fade-right">
            <div className="hero_container">
                <img src="images/contactimage.jpg" alt="Contact" /> 
            </div>
        </div>
        <div className="form-container" data-aos="fade-left">
            { submitted ? <ThankYou /> : <Form handleSubmit={handleSubmit}/> }
        </div>
    </section>
    );
}